html {
	scroll-behavior: smooth;
}
* {
	box-sizing: border-box;
}
body {
	margin: 0;
	font-family: 'Fira Code', monospace;
	font-family: 'Raleway', sans-serif;
}
a {
	color: initial;
	text-decoration: none;
}
a.tdu:hover {
	text-decoration: underline;
	text-decoration-color: currentColor;
}
.bookmarkOffset::before {
	content: '';
	display: block;
	height: 150px;
	margin-top: -150px;
	visibility: hidden;
}
